<template>
  <init ref="init"
        :options='options'
        :news="news"
        :system="system"
        labelPosition="top"
        @onSubmit="handleInit"></init>
</template>

<script>
import Init from '@/components/Login'
import { setInitStatus } from '@/utils/localStorage.js'
const crypto = require('crypto')

export default {
  components: {
    Init
  },
  data () {
    return {
      system: {
        name: '欢迎来到',
        title: '邻里托管运营中心'
      },
      // 新闻
      news: [{ title: '系统尚未初始化，请设置超级管理员账号' }],
      options: {
        // 账号栏配置,不传入则采用默认账号栏配置
        account: {
          // 账号栏label名,显示在输入框上方
          // label: '用户',
          // 账号栏左侧图标,需要formStyle为icon
          icon: 'el-icon-user',
          // 账号输入框表单校验正则,作为登录操作以及第一次点击登录后的校验依据
          placeholder: '请输入管理员账号',
          clearable: true,
          // 校验规则
          rules: [
            { required: true, message: '请输入管理员账号', trigger: 'change' },
            { min: 3, max: 16, message: '长度在 3 到 16 个字符', trigger: 'change' }
          ]
        },
        // 密码栏配置,不传入则采用默认密码栏设置
        password: {
          // label: '密码',
          icon: 'el-icon-lock',
          placeholder: '请输入密码',
          type: 'password',
          clearable: true
        },
        confirm: {
          // label: '密码',
          icon: 'el-icon-lock',
          type: 'password',
          placeholder: '请确认密码'
        },
        button: {
          label: '初始化'
          // icon: 'el-icon-lock'
        }
      }
    }
  },
  methods: {
    // 哈希加密(SHA1)
    encrypt (password) {
      const hash = crypto.createHash('sha1')
      hash.update(password)
      return hash.digest('hex')
    },
    async handleInit (form) {
      let params = {
        loginAccount: form.account,
        loginPassword: this.encrypt(form.password)
      }
      let res
      try {
        res = await this.$api.user.init(params)
      } catch (err) {
        this.$refs.init.resetButton() // 恢复按钮
        console.log(err)
      }
      if (res && res.data && res.data.code === 0) {
        setInitStatus(true)
        this.step = 'beforeLogin'
        this.$emit('initCompleted')
        this.$refs.init.resetButton() // 恢复按钮
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
        this.$refs.init.resetButton() // 恢复按钮
      }
    }
  }
}
</script>

<style>
</style>
