<template>
  <div class="ym-container">
    <div v-show="false">v0.2.24</div>
    <div class="ym-card">
      <el-card class="center">
        <el-row class="ym-card-box">
          <el-col :sm="12"
                  class="ym-card-box-ad hidden-xs-only">
            <!--LOGO-->
            <div class='ym-card-box-ad__logo'
                 :style="logoOptions.style">
              <template v-if="logoOptions.logoType=='img'">
                <img :src="logoOptions.url"
                     :alt="logoOptions.title||''">
              </template>
              <template v-if="logoOptions.logoType=='text'">
                <span class="ym-card-box-ad__title">{{logoOptions.title}}</span>
              </template>
            </div>
          </el-col>
          <el-col :xs="24"
                  :sm="12"
                  class="ym-card-form">
            <transition name="fade-transverse-reverse"
                        mode="out-in">
              <!-- 初始化 -->
              <template v-if="step === 'init'">
                <init @initCompleted="initCompleted"></init>
              </template>
              <!-- 初始化成功 -->
              <template v-else-if="step === 'beforeLogin'">
                <div class="init-success">
                  <img src="./img/complete.png"
                       alt="initialization complete!">
                  <p class="info"><span>{{title}}</span> 初始化成功</p>
                  <p class="detail">点击“确定”按钮进入登录界面</p>
                  <el-button type="primary"
                             @click="nextStep"
                             class="submit-button">确定</el-button>
                </div>
              </template>
              <!-- 登录 -->
              <template v-else-if="step === 'login'">
                <login class="login"></login>
              </template>
            </transition>
          </el-col>
        </el-row>
      </el-card>
    </div>

    <!-- 备案信息栏 -->
    <div class="bottom-box">
      <span></span>
    </div>
  </div>
</template>

<script>
import Login from './login'
import Init from './init'
import { getInitStatus, setInitStatus } from '@/utils/localStorage.js'
import { Message } from 'element-ui'
import user from '@/service/modules/user.js'

export default {
  components: {
    Login,
    Init
  },
  data () {
    return {
      title: '邻里托管运营中心',
      step: '', // 当前步骤('init', 'beforeLogin', 'login')
      loading: false,
      // 左上角logo配置项
      logoOptions: {
        logoType: 'img',
        title: '待定',
        url: require('./img/logo-header.png'),
        style: {
          width: '144px',
          left: '16px',
          top: '24px'
        }
      }
    }
  },
  mounted () {
    if (getInitStatus()) {
      this.step = 'login'
    } else {
      user.checkInit()
        .then(res => {
          if (res.data.code === 0) {
            if (res.data.data.checkInit === true) {
              console.log('check initialization[Finished]')
              setInitStatus(true)
              this.step = 'login'
              // this.step = 'login'
            } else {
              setInitStatus(false)
              this.step = 'init'
            }
          } else {
            console.log('check initialization[Unfinished]')
            Message.error(res.data.message)
            this.step = 'init'
          }
        })
        .catch(err => {
          console.log(err)
          throw new Error(err)
        })
    }
  },
  methods: {
    initCompleted () {
      this.step = 'beforeLogin'
    },
    nextStep () {
      this.step = 'login'
    }
  }
}
</script>

<style lang="scss">
.ym-container {
  width: 100%;
  min-height: 100%;
  position: relative;
  overflow: auto;
  background: url("./img/bg_img.png") no-repeat;
  background-color: "#FFFFFF";
  background-size: 100% 100%;
  .ym-card {
    position: absolute;
    width: 100%;
    transform: translateY(-50%);
    top: 50%;
    & .el-card .el-card__body {
      padding: 0;
    }
    &-box {
      display: flex;
      &-ad {
        & img {
          margin: 0 auto;
          display: block;
          width: 100%;
        }
        background: url("./img/ad.png") no-repeat;
        background-color: #f9fafa;
        background-position: bottom right;
        &__logo {
          position: absolute;
          margin: 5px 15px;
          overflow: hidden;
          user-select: none;
        }
        &__title {
          font-family: "fangsong";
          font-size: $--font-size-extra-large;
          color: $--color-primary;
          text-shadow: 0px 0px 1px $--color-primary;
        }
      }
    }
    &-form {
      padding: 40px;
      min-height: 546px;
      .init-success {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        img {
          height: 150px;
          width: 150px;
          margin-top: 20px;
          margin-bottom: 20px;
        }
        .info {
          font-size: $--font-h3-size;
          span {
            font-weight: 400;
            color: $--color-primary;
          }
        }
        .detail {
          color: $--color-text-secondary;
        }
        .submit-button {
          width: 100%;
          margin-top: 48px;
          margin-bottom: 32px;
          font-size: $--font-size-large;
        }
      }
      .login {
        .ym-login-form {
          margin-top: 100px;
          .button {
            margin-top: 60px;
          }
        }
      }
    }
  }
  .center {
    margin-right: auto;
    margin-left: auto;
    float: initial;
    max-width: 970px;
  }
  // .left {
  //   margin-left: 10%;
  //   margin-right: auto;
  //   float: initial;
  //   max-width: 970px;
  // }
  // .right {
  //   margin-right: 10%;
  //   margin-left: auto;
  //   float: initial;
  //   max-width: 970px;
  // }

  // 底部备案信息
  .bottom-box {
    font-size: $--font-size-extra-small;
    color: $--color-text-regular;
    position: absolute;
    bottom: 32px;
    left: 10%;
    right: 10%;
    text-align: center;
  }
}
</style>
