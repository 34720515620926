<template>
  <login ref="login"
         :options='options'
         :system="system"
         labelPosition="top"
         @onSubmit="handleLogin"></login>
</template>

<script>
import Login from '@/components/Login'
import router, { resetRouter } from '@/router'

const crypto = require('crypto')

export default {
  components: {
    Login
  },
  data () {
    return {
      system: {
        name: '欢迎来到',
        title: '邻里托管运营中心'
      },
      // 新闻
      // news: [{ title: '这是一个用于展示的应用模块' }],
      options: {
        // 账号栏配置,不传入则采用默认账号栏配置
        account: {
          // 账号栏label名,显示在输入框上方
          // label: '用户',
          // 账号栏左侧图标,需要formStyle为icon
          icon: 'el-icon-user',
          // 账号输入框表单校验正则,作为登录操作以及第一次点击登录后的校验依据
          placeholder: '请输入用户名',
          clearable: true,
          // 校验规则
          rules: [
            { required: true, message: '请输入用户名', trigger: 'change' },
            { min: 3, max: 16, message: '长度在 3 到 16 个字符', trigger: 'change' }
          ]
        },
        // 密码栏配置,不传入则采用默认密码栏设置
        password: {
          // label: '密码',
          icon: 'el-icon-lock',
          placeholder: '请输入密码',
          type: 'password',
          clearable: true
        },
        button: {
          label: '登录'
          // icon: 'el-icon-lock'
        }
      }
    }
  },
  methods: {
    // 哈希加密(SHA1)
    encrypt (password) {
      const hash = crypto.createHash('sha1')
      hash.update(password)
      return hash.digest('hex')
    },
    handleLogin (form) {
      let params = {
        loginAccount: form.account,
        loginPassword: this.encrypt(form.password)
      }
      this.$store.dispatch('login', params)
        .then((accessRoutes) => {
          if (window.PERMISSIVE_ROUTES) {
            console.log('重复检测', router)
            // 先初始化路由，addRoutes只会加入，不会剔除重复项
            // 页面不刷新登录两个不同菜单的账号会发生菜单重叠
            resetRouter()
            router.addRoutes(accessRoutes) // 动态添加可访问路由表 // 动态路由相关
          }
          this.$message.success('登录成功！')
          // setTimeout(() => {
          this.$router.push({ path: '/home' })
          // }, 1000)
        })
        .catch((error) => {
          this.$message.error(error)
          this.$refs.login.resetButton() // 恢复按钮
        })
    }
  }
}
</script>
