<template>
  <div class="ym-login">
    <!-- 登录组件标题 -->
    <div class="ym-login-title"
         v-if="system">{{system.name}} <span>{{ system.title }}</span></div>
    <!-- 登录组件轮播新闻 -->
    <el-carousel v-if="news"
                 class="ym-login-news"
                 :autoplay="false"
                 indicator-position="outside"
                 arrow="never"
                 height="50px">
      <el-carousel-item v-for="(item,index) in news"
                        :key="index">
        {{item.title}}
      </el-carousel-item>
    </el-carousel>
    <div class="ym-login-form">
      <el-form :label-position="labelPosition"
               :rules="rules"
               :model="form"
               :ref="refForm"
               :label-width="labelPosition === 'top' ? '80px' : '100px'"
               :class="star ? 'star': ''">
        <div v-for="(item,index) in applyOptions"
             :key="index">
          <!--图片验证码-->
          <el-form-item v-if="index === 'imgVerify'"
                        :label="item.label"
                        :prop="index"
                        class="verify">
            <div class="verify-input">
              <el-input v-model="form[index]"
                        v-bind="[item]"
                        :prefix-icon="item.icon"></el-input>
              <img class="verify-btn"
                   :src="item.url"
                   @click="chengeImgVerify()">
            </div>
          </el-form-item>
          <!-- 手机号码 -->
          <el-form-item v-else-if="index === 'phone'"
                        :label="item.label"
                        :prop="index"
                        class="phone">
            <el-input v-model="form[index]"
                      v-bind="[item]"
                      :prefix-icon="item.icon">
              <el-select v-model="form.areaCode"
                         slot="prepend">
                <el-option label="+86"
                           key="86"
                           value="86">
                  <span>中国&nbsp;&nbsp;+86</span>
                </el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <!--短信验证码-->
          <el-form-item v-else-if="index === 'phoneVerify'"
                        :label="item.label"
                        :prop="index"
                        class="verify">
            <div class="verify-input">
              <el-input v-model="form[index]"
                        v-bind="[item]"
                        :prefix-icon="item.icon"></el-input>
              <el-button :disabled="!isDisabled"
                         @click="sendPhoneMessage()">{{ phoneText ? phoneText : '发送' }}</el-button>
            </div>
          </el-form-item>
          <!--注册协议-->
          <el-form-item v-else-if="index === 'agree'"
                        :prop="index"
                        class="agree">
            <template v-if="item.label">
              <el-checkbox v-model="form[index]"></el-checkbox>
              <span class='agree-text'>{{item.label}}</span>
            </template>
            <template v-if="item.protocol">
              <template v-for="(p,index) in item.protocol">
                <span :key="p.name">
                  <span v-if='index > 0'>和</span>
                  <router-link target="_blank"
                               class="agree-url"
                               :to="{name:p.url}">《{{p.name}}》</router-link>
                </span>
              </template>
            </template>
            <!--其他连接-忘记密码-->
            <router-link v-if="item.forget"
                         target="_blank"
                         style="float: right"
                         :to="{name:item.forget.url}">{{item.forget.label}}</router-link>
          </el-form-item>
          <!--其他-->
          <el-form-item v-else-if="index === 'other'">
            <router-link v-if="applyOptions.other.left"
                         target="_blank"
                         :to="{name:item.left.url}">{{item.left.label}}</router-link>
            <router-link v-if="applyOptions.other.right"
                         target="_blank"
                         style="float: right"
                         :to="{name:item.right.url}">{{item.right.label}}</router-link>
          </el-form-item>
          <!--按钮-->
          <el-form-item v-else-if="index === 'button'"
                        class="button">
            <el-button type="primary"
                       :loading="loading"
                       v-bind="[item]"
                       @click="beforeLogin()"
                       class="submit-button">{{item.label}}</el-button>
          </el-form-item>
          <!-- 注册和登录切换 -->
          <el-form-item v-else-if="index === 'switch'"
                        class="switch">
            <span>{{item.text}}</span>
            <a class="switch-url"
               @click="handleSwitch">{{item.label}}</a>
          </el-form-item>
          <!--账户/密码等文本框-->
          <el-form-item v-else
                        :label="item.label"
                        :prop="index">
            <el-input v-model="form[index]"
                      v-bind="[item]"
                      :prefix-icon="item.icon"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <!-- <p class="guide"
        v-if="cardBottom">{{cardBottom.guideText}}<router-link :to="{name:cardBottom.toUrl}"> {{cardBottom.linkText}} </router-link>
      </p> -->
    </div>
  </div>
</template>

<script>
import { isPhone } from '@/utils/validate.js'
export default {
  props: {
    labelPosition: {
      type: String,
      default: 'right'
    },
    refForm: {
      type: String,
      default: 'form'
    },
    // 表单中的必填*
    star: {
      type: Boolean,
      default: false
    },
    // 标题
    system: {
      type: Object,
      default: () => {
        return {
          name: '',
          title: ''
        }
      }
    },
    news: {
      type: Array
    },
    // 表单配置项
    options: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    // 验证手机号是否合法
    let checkPhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号码'))
      } else if (!isPhone(value)) {
        callback(new Error('手机号码不合法'))
      } else {
        callback()
      }
      this.isDisabled = isPhone(value) ? !(parseInt(this.phoneText) >= 0) : false
    }
    // 短信验证码是否为空
    let checkSmscode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入短信验证码'))
      } else {
        callback()
      }
    }
    // 图片验证码是否为空
    let checkImgcode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入图片验证码'))
      } else {
        callback()
      }
    }
    // 验证密码
    let validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        callback()
      }
    }
    // 二次验证密码
    let validateCheckPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    let checkAgree = (rule, value, callback) => {
      if (this.applyOptions.agree && this.applyOptions.agree.label) {
        if (value === '' || value === false) {
          callback(new Error('请勾选同意服务条款'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      loading: false,
      isDisabled: false, // 发送验证码按钮状态
      phoneText: '发送验证码',
      countDown: 60,
      form: { // 表单值
        account: '',
        phone: '',
        areaCode: '86',
        password: '',
        confirm: '',
        phoneVerify: '',
        imgVerify: '',
        agree: ''
      },
      applyOptions: {}, // 表单项配置项
      rules: {
        account: [
          { required: true, message: '请输入用户名', trigger: 'change' },
          { min: 3, max: 5, message: '长度在 3 到 15 个字符', trigger: 'change' }
        ],
        phone: [{ required: true, validator: checkPhone, trigger: 'change' }],
        password: [{ required: true, validator: validatePass, trigger: 'change' }],
        confirm: [{ required: true, validator: validateCheckPass, trigger: 'change' }],
        phoneVerify: [{ required: true, validator: checkSmscode, trigger: 'change' }],
        imgVerify: [{ required: true, validator: checkImgcode, trigger: 'change' }],
        agree: [{ required: true, validator: checkAgree, trigger: 'blur' }]
      }
    }
  },
  mounted () {
    // 初始化
    this.applyOptions = this.options
    // 更新验证规则
    Object.keys(this.options).forEach(item => {
      if (this.rules[item] && this.options[item].rules) {
        this.rules[item] = this.options[item].rules // 替换校验规则
      }
    })
    // 回车登录
    window.addEventListener('keydown', this.keyDown)
  },
  beforeDestroy () {
    console.log('login beforeDestroy')
    window.removeEventListener('keydown', this.keyDown, false)
  },
  methods: {
    // 点击图片验证码
    chengeImgVerify () {
      // this.$emit('chengeImgVerify')
      this.applyOptions.imgVerify.clickEvent()
    },
    // 点击发送验证码按钮（使用发送验证码必须配置手机号输入框）
    sendPhoneMessage () {
      let phone = this.form.phone
      if (isPhone(phone)) {
        // this.initCountDown()
        // this.$emit('sendPhoneMessage')
        this.applyOptions.phoneVerify.clickEvent.call(this, phone).then(res => {
          let time = this.countDown
          this.phoneText = '已发送'
          if (this.isDisabled) {
            this.isDisabled = false
            let timer = setInterval(() => {
              time--
              this.phoneText = time + ' 秒'
              if (time === 0) {
                clearInterval(timer)
                this.phoneText = '重新发送'
                this.isDisabled = true
              }
            }, 1000)
          }
          if (res && res.data && res.data.code === 0) {

          } else {
            if (res && res.data) {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          }
        }).catch(err => {
          this.$message({
            type: 'error',
            message: err
          })
        })
      }
    },
    // 提交按钮触发事件
    beforeLogin () {
      this.$refs[this.refForm].validate((valid) => {
        if (valid) {
          this.loading = true
          this.$emit('onSubmit', this.form)
          // this.applyOptions.button.clickEvent(this.form)
        } else {
          console.log('error submit!')
          // 恢复按钮
          this.resetButton()
          return false
        }
      })
    },
    // 清除按钮的loading状态
    resetButton () {
      this.$set(this, 'loading', false)
    },
    keyDown (e) {
      // 如果是回车则执行登录方法
      if (e.keyCode === 13) {
        this.beforeLogin()
      }
    },
    handleSwitch () {
      this.$emit('handleSwitch')
    }
  }
}
</script>

<style lang="scss">
.ym-login {
  color: $--color-text-primary;
  &-title {
    width: max-content;
    font-size: $--font-h3-size;
    text-align: center;
    margin: 20px 0;
    color: #66ccff;
    span {
      font-weight: 400;
      color: $--color-primary;
      color: #66ccff;
    }
  }
  // 表头新闻
  &-news {
    margin: 30px 0;
    & .el-carousel__indicators--outside {
      text-align: left;
      & .is-active button {
        background-color: $--color-primary;
      }
    }
  }
  &-form {
    .el-form-item__label {
      padding-right: 24px;
      color: $--color-text-regular;
    }
    .el-form-item__label:before {
      display: none;
    }
    .star {
      .el-form-item__label:before {
        display: inherit;
      }
    }
    .el-form--label-right,
    .el-form--label-top {
      .submit-button {
        width: 100%;
        margin: 10px 0px;
        font-size: $--font-size-large;
        background-color: #66ccff !important;
        border-color: #66ccff !important;
        border-radius: 40px;
        &:hover {
          background-color: #66ccff !important;
        }
      }
      .phone {
        .el-input-group__prepend {
          width: 24%;
          background-color: #fff;
          div.el-select {
            .el-input__inner {
              color: $--color-text-regular;
            }
          }
        }
      }
      // 验证码图形验证码
      .verify {
        &-input {
          width: 100%;
          display: inline-block;
        }
        .el-input {
          width: calc(70% - 16px);
          text-align: center;
        }
        .el-button {
          margin-left: 16px;
          width: 30%;
          text-align: center;
          padding: 12px;
        }
        &-btn {
          margin-left: 20px;
          width: 40%;
          height: 40px;
          float: right;
        }
      }
      // 同意
      .agree {
        .el-form-item__content,
        .el-checkbox,
        .el-link {
          margin: 0px !important;
          line-height: 19px;
          // text-align: center;
        }
        &-url {
          color: $--link-hover-color;
          &:hover {
            color: $--link-color;
          }
        }
        &-text {
          padding-left: 10px;
        }
      }
      .button,
      .switch {
        .el-form-item__content {
          margin: 0px !important;
          text-align: center;
        }
      }
      .switch {
        &-url {
          color: $--link-hover-color;
          cursor: pointer;
          &:hover {
            color: $--link-color;
          }
        }
      }
    }
    .el-form--label-right {
      .verify {
        .el-input {
          width: calc(70% - 16px);
        }
        .el-button {
          margin-left: 16px;
          width: 30%;
        }
      }
    }
    .el-form--label-top {
      .verify {
        .el-input {
          width: calc(60% - 20px);
        }
        .el-button {
          margin-left: 20px;
          width: 40%;
        }
      }
    }
  }
}
</style>
